<template>
  <div class="">
    <el-drawer 
      :close-on-press-escape="true"
      title="Booking Info" :visible.sync="showBooking" direction="rtl" :size="500"
          :before-close="handleClose">
          <div v-if="booking" style="padding: 20px">
            <div>Booked by: {{ booking.user.name }}</div>
            <div>Time: {{ booking.time }}</div>
            <div><el-link type="warning" :href="'/meeting-room/booking/'+booking.code+'/view'">View Full Details</el-link></div>
          </div>
    </el-drawer>

    <el-card class="list-card" >

      <meeting-room-picture-preview :meeting_room="meeting_room"></meeting-room-picture-preview>
      <hr>

      <h5>{{ meeting_room.name }} - {{ meeting_room.office.name }}</h5>
      <div>Capacity: {{ meeting_room.capacity }} people</div>
      <div>Address: {{ meeting_room.office.address.full_address }}</div>
      
      <div v-for="hour in filteredHours()" :key="hour.value">
        <el-button class="btn-block" @click="getBooking(hour.value)" 
        :type="isReserved(hour.value)">{{ hour.text+taken(hour.value) }}</el-button>
      </div>
    </el-card>
  </div>
</template>
<script>

import MeetingRoomPicturePreview from '../Client/MeetingRoomPicturePreview.vue';

export default {
  props: ['meeting_room'],
  components: { MeetingRoomPicturePreview },
  data() {
    return {
      booking: null,
      showBooking: false
    }
  },
  created: function () {
  
  },
  methods: {
    handleClose() {
       this.booking = null
      this.showBooking = false
    },
    getBooking(hour) {
      if (!this.taken(hour).length) return false
      let index = this.meeting_room.booked_hours.indexOf(hour);
      this.booking = this.meeting_room.bookings[index]
      this.showBooking = true
      console.log(this.booking)
    },
    isReserved(hour) {
      return this.taken(hour).length ? 'success':''
    },
    taken(hour) {
      return this.meeting_room.booked_hours.includes(hour) ? '(Reserved)':''
    },
    filteredHours() {
      let hours = this.hours()
      let filteredHours = []
      let self = this
      hours.forEach(function (hour) {
        if (self.meeting_room.hours.includes(hour.value)) filteredHours.push(hour)
      })

      return filteredHours
    },
  }
}
</script>
<style lang="scss"></style>
