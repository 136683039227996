<template>
  <ValidationObserver ref="form">
    <form @submit.prevent="validate">
      <h5 class="info-text"></h5>
      <div class="row justify-content-center">
        <div class="col-sm-6">
          <ValidationProvider name="start_date" rules="required" v-slot="{ passed, failed }">
            <fg-input type="date" name="start_date" :error="failed ? 'The start date field is required' : null" :hasSuccess="passed"
              placeholder="Start Date (required)" v-model="form.start_date" addon-left-icon="nc-icon nc-single-02">
            </fg-input>
          </ValidationProvider>
        </div>

        <div class="col-sm-6">
          <ValidationProvider name="end_date" rules="required" v-slot="{ passed, failed }">
            <fg-input type="date" :min-date="form.start_date" name="end_date" :error="failed ? 'The end date field is required' : null" :hasSuccess="passed"
              placeholder="End Date (required)" v-model="form.end_date" addon-left-icon="nc-icon nc-single-02">
            </fg-input>
          </ValidationProvider>
        </div>

        <div class="col-md-12">
            <el-button round type="info" @click="validate()"><i class="nc-icon nc-money-coins" ></i> Download</el-button>
        </div>
      </div>
    </form>
  </ValidationObserver>
</template>
<script>

import { extend } from "vee-validate";
import { required } from "vee-validate/dist/rules";

extend("required", required);

import { Transaction } from '@/resources/transaction'

export default {
  data() {
    return {
      form: {start_date: null, end_date:null},
      loading: false,
    }
  },
  watch: {
    'form.start_date': function () {
      if (this.form.start_date) this.form.end_date = this.form.start_date
    }
  },
  created: function () {

  },
  methods: {
    validate() {
      return this.$refs.form.validate().then(res => {
        if (res) this.submit()
        // this.$emit('on-validated', res, this.form, 'company')
        return res
      })
    },
    submit() {
      this.loading = true
      Transaction.download(this.form)
        .then(result => {
          console.log(result.data.link)
          const link = document.createElement('a')
          link.href = result.data.link
          link.download = result.data.filename
          link.click()
        })
        .catch(() => {
        }).finally(() => {
          this.loading = false
        })
    }
  }
}
</script>
<style lang="scss"></style>
