<template>
  <ValidationObserver v-slot="{ handleSubmit }" v-loading="loading">
    <form @submit.prevent="handleSubmit(submit)">
      <div class="card">
        <div class="card-header">
          <h4 class="card-title">
            {{ form.id ? 'Update Holiday' : 'Create new Holiday' }}
          </h4>
        </div>
        <div class="card-body">
          <div class="form-group">
            <label>Name</label>
            <ValidationProvider name="name" rules="required" v-slot="{ passed, failed }">
              <fg-input type="text" :error="failed ? 'The name field is required' : null" :hasSuccess="passed" name="name"
                v-model="form.name">
              </fg-input>
            </ValidationProvider>
          </div>

          <div class="form-group">
              <label>Date</label>
              <ValidationProvider name="date" rules="required" v-slot="{ passed, failed }">
                <fg-input type="date" :error="failed ? 'The date field is required' : null" :hasSuccess="passed" name="date"
                  v-model="form.date">
                </fg-input>
              </ValidationProvider>
            </div>


          <div class="form-group">
            <label>Type</label>
            <select class="form-control" v-model="form.type">
              <option :value="0">Holiday</option>
              <option :value="1">Other</option>
            </select>
          </div>

  
        </div>
        <div class="card-footer text-right">
          <p-button type="info" native-type="submit">Save</p-button>
        </div>
      </div>
    </form>
  </ValidationObserver>
</template>
<script>

import { extend } from "vee-validate";
import { required } from "vee-validate/dist/rules";
import { Holiday } from '@/resources/holiday'

extend("required", required);

export default {
  props: ['holiday'],
  data() {
    return {
      form: {
        name: "",
        type: null,
        date: null
      },
      loading: false
    };
  },
  created: function () {
    if (this.holiday) this.form = Object.assign({}, this.holiday)
  },
  watch: {
    holiday() {
      if (this.holiday) this.form = Object.assign({}, this.holiday)
    }
  },
  methods: {
    reset() {
      this.form = {
        name: "",
        type: null,
        date: null
      }
    },
    submit() {
      this.loading = true
      let request = this.form.id ? Holiday.update(this.form) : Holiday.create(this.form)

      request
        .then(result => {
          this.$notify({
            type: 'success',
            title: "Success",
            text: 'Holiday has been saved',
          });
          this.reset()
          this.$emit('update', true)
        })
        .catch(() => {
         
        }).finally(() => {
          this.loading = false
        })
    }
  }
}
</script>
<style></style>
