<template>
  <div class="register-page">
    <app-navbar></app-navbar>
    <div class="wrapper wrapper-full-page">
      <div class="full-page login-page section-image">
        <div class="content">
          <div class="container">
            <div class="col-lg-4 col-md-6 ml-auto mr-auto">
              <forgot-password-form></forgot-password-form>
            </div>
          </div>
        </div>
        <app-footer></app-footer>
        <background></background>
      </div>
    </div>
  </div>
</template>
<script>

import AppNavbar from '@/components/Dashboard/Views/Pages/Layout/AppNavbar'
import AppFooter from '@/components/Dashboard/Views/Pages/Layout/AppFooter'
import { Card, Button } from 'src/components/UIComponents';
import ForgotPasswordForm from './ForgotPasswordForm.vue';


export default {
  components: {
    AppNavbar,
    AppFooter,
    Card,
    ForgotPasswordForm,
    [Button.name]: Button
  },
  created: function () {
  },
  methods: {

  },
  data() {
    return {
      email: null
    }
  },
}
</script>
<style></style>
