<template>
  <div v-if="user" class="">
    <div class="row">
      <div class="col-md-3">
        <datepicker :format="customFormatter" v-model="date" :use-utc="false" input-class="form-control" bootstrap-styling placeholder="Search Date"></datepicker>

        <el-autocomplete style="width:100%" clearable @change="onRoomChanged" v-model="roomName"
          :fetch-suggestions="getRooms" placeholder="Search Room" value="name" label="name"
          @select="handleSelect"></el-autocomplete>
      </div>
      <div class="col-md-9">
        <div class="row">
          <div class="col-md-4" v-for="meeting_room, i in meeting_rooms" :key="i">
              <one-booking-view :meeting_room="meeting_room"></one-booking-view>
          </div>
        </div>

        <div class="no-data-text" v-if="!meeting_rooms.length">No bookings found</div>
      </div>
    </div>
  </div>
</template>
<script>

import { mapState } from 'vuex';
import { MeetingRoom } from '@/resources/meeting_room'
import Datepicker from 'vuejs-datepicker';
import PPagination from 'src/components/UIComponents/Pagination.vue'
import MeetingRoomPicturePreview from '../Client/MeetingRoomPicturePreview.vue';
import OneBookingView from './OneBookingView.vue';

export default {
  props: ['type'],
  components: { Datepicker, PPagination, MeetingRoomPicturePreview, OneBookingView },
  data() {
    return {
      loading: false,
      date: new Date(),
      meeting_rooms: [],
      pagination: {
        perPage: 5,
        currentPage: 1,
        total: 0
      },
      room: null,
      roomName: null,
      all: false,
      plain_date: null
    }
  },
  created: function () {
    let newDate = this.$moment().utc();
    
    if (this.$route.query.date) {
      let split = this.$route.query.date.split('/')
      newDate.set('year', split[0]);
      newDate.set('month', split[1] - 1);  // April
      newDate.set('date', split[2]);
      this.plain_date = newDate.format('YYYY/MM/DD')
     
    } else {
      this.date = new Date()
    }

     this.getBookings()
  },
  watch: {
    plain_date: function () {
       this.getBookings()
    },
    date: function () {
      this.all = false
      let newDate = this.$moment(this.date)
      this.plain_date = newDate.format('YYYY/MM/DD')
    }
  },
  methods: {
    setPlainDate() {
      
    },
    customFormatter(date) {
      if (!this.plain_date) {
        return null
      }

      let split = this.plain_date.split('/')
      let newDate = this.$moment().utc();
      newDate.set('year', split[0]);
      newDate.set('month', split[1] - 1);  // April
      newDate.set('date', split[2]);

      return newDate.format('MMMM DD, YYYY')
    },
    showAll() {
      this.all = true
      this.date = null
      this.getBookings()
    },
    handleSelect(item) {
      this.room = Object.assign({}, item)
      this.getBookings()
    },
    onRoomChanged(value) {
      if (!value) {
        this.room = null
        this.getBookings()
      }
    },
    getRooms(queryString, cb) {
      MeetingRoom.get({ params: { term: queryString, status: 1, page: 1, per_page: 5, type: this.type} })
        .then(result => {
          let list = []

          result.data.rooms.forEach(room => {
            list.push({ value: room.name, id: room.id })
          });

          cb(list)
        })
        .catch((err) => {
          console.log(err)
          cb([])
        }).finally(() => {

        })
    },
    getBookings() {
      this.loading = true
      let d = this.all ? null:this.plain_date

      MeetingRoom.getBookings({
        params: {
          page: 1, per_page: 999,
          date: d, all: this.all, meeting_room_id: this.room ? this.room.id : null, type: this.type,
          manage: true
        }
      })
        .then(result => {
          this.meeting_rooms = result.data.meeting_rooms
          // if (!this.plain_date) this.plain_date = result.data.date

          if (d && this.$route.query.date != d) {
            this.$router.replace({ query: { date: d } })
          }
        })
        .catch((error) => {
          console.log(error)
        }).finally(() => {
          this.loading = false
        })
    }
  },
  computed: mapState(['user']),
}
</script>
<style lang="scss"></style>
