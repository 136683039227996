<template>
  <ValidationObserver v-slot="{ handleSubmit }" ref="form">
    <form @submit.prevent="handleSubmit(submit)" v-loading="loading">
      <div class="card">
        <div class="card-header">
          <h4 class="card-title">
            Forgot Your Password?
          </h4>
          <div>Enter your email address</div>
        </div>
        <div class="card-body">
          <div class="form-group">
            <ValidationProvider name="email" rules="required|email" v-slot="{ passed, failed }">
              <fg-input type="email" :error="failed ? 'The Email field is required' : null" :hasSuccess="passed"
                name="email" v-model="form.email" :addonLeftIcon="'el-icon-message'">
              </fg-input>
            </ValidationProvider>
          </div>
        </div>
        <div class="card-footer text-right">
          <p-button type="success" native-type="submit" @click="checkBeforeSubmit()">Submit</p-button>
        </div>
      </div>
    </form>
  </ValidationObserver>
</template>
<script>
import { extend } from "vee-validate";
import { required, email, confirmed, regex } from "vee-validate/dist/rules";
import { Signup } from '@/resources/signup'
import { Authenticate } from '@/resources/authenticate'
import Swal from 'sweetalert2'

extend("email", email);
extend("required", required);
extend("confirmed", confirmed);
extend("regex", regex);

export default {
  data() {
    return {
      form: {
        name: "",
        email: "",
        password: "",
        confirmPassword: null
      },
      message: null,
      loading: false
    };
  },
  methods: {
    validate() {
      return this.$refs.form.validate().then(res => {
        return res
      })
    },
    checkBeforeSubmit() {
      // console.log(this.validate())
    },
    submit() {
      this.loading = true
      Signup.forgotPassword(this.form)
        .then(result => {
          this.success()
        })
        .catch(error => {
          this.$errors = error.response.data
        }).finally(() => {
          this.loading = false
        })
    },
    async success() {
      const { value: res } = await Swal.fire({
        title: "Success!",
        text: "A password reset message was sent to your email address. Please check your email for a password reset link. If you do not receive it within a few minutes, please check your spam folder. If you are still having trouble, please contact our support team for assistance.",
        icon: "success",
        confirmButtonClass: "btn btn-success",
        buttonsStyling: false
      })

      this.$router.push({ name: 'Login', query: { refresh: true } })
    }
  }
}
</script>
<style></style>
