<template>
  <div>
    <h5 class="card-title">Select Company</h5>

    <div  class="row col-md-6 ml-auto mr-auto" >      

       <div class="input-group mb-3">
          <input v-model="term" type="text" class="form-control" placeholder="Company name" aria-label="Company name" aria-describedby="basic-addon2">
        </div>

    </div>

    
    
    <div v-if="!companies.length" class="row col-md-6 ml-auto mr-auto" >
         <div style="text-align:center;width: 100%">No data found</div>
    </div>

    <div class="row" v-for="company in companies" :key="company.id">
      <div class="card col-md-6 ml-auto mr-auto">
        <div class="card-header">
          
        </div>
        <div class="card-body">
          <p>{{ company.name }}</p>
          <hr>
          <button class="btn btn-outline-secondary" type="button" style="margin: 2px 2px;" @click="selectCompany(company)">Select</button>
        </div>
      </div>
    </div>
  

    <div class=" col-md-6 ml-auto mr-auto" >
      <p-pagination v-if="companies.length" class=""
                    v-model="pagination.currentPage"
                    :per-page="pagination.perPage"
                    :total="pagination.total">
      </p-pagination>
    </div>
  </div>
</template>
<script>

  import PPagination from 'src/components/UIComponents/Pagination.vue'
  import { Company } from '@/resources/company'
  import { Authenticate } from '@/resources/authenticate'

  export default{
    components: {
      PPagination
    },
    data () {
      return {
        companies: [],
        pagination: {
          perPage: 5,
          currentPage: 1,
          perPageOptions: [5, 10, 25, 50],
          total: 0
        },
        term: null,
        status: 1,
        loading: true
      }
    },
    watch: {
      term: function() {
        if (!this.term || this.term.length > 2) {
          this.getCompanies()
        }
      },
      'pagination.perPage': function() {
        if ( this.pagination.currentPage == 1) {
          this.getCompanies()
        } else {
          this.pagination.currentPage = 1
        }
      },
      'pagination.currentPage': function() {
        this.getCompanies()
      }
    },
    created: function() {
      this.getCompanies()
    },
    methods: {
      getCurrentUser() {
        let self = this
        Authenticate.getUser(function(response){
          if (response.user) {
            self.$store.commit('saveUser',response.user);
            self.$router.push({name: 'Home'})
          }
        })
      },
      selectCompany(company) {
        this.loading = true
        Company.setUserCompany(company.id)
        .then(result => {
            this.getCurrentUser()
        })
        .catch( error => {
          let message = error.response.data.message || 'Unable to save this time'
          this.$notify({
            type: 'danger',
            title: "Error",
            text: message,
          });
        }).finally( () => {
          this.loading = false
        })
      },
      getCompanies() {
        this.loading = true
        Company.userCompanies({params: {term: this.term, status: this.status,page: this.pagination.currentPage}})
        .then(result => {
            if(result.data.companies)
            this.companies = result.data.companies
            this.pagination = result.data.meta
        })
          .catch(() => {
          this.$notify({
            type: 'danger',
            title: "Error",
            text: 'Unable to fetch companies',
          });
        }).finally( () => {
          this.loading = false
        })
      }
    }
  }
</script>
<style lang="scss">

</style>
