<template>
  <div v-loading.fullscreen.lock="loading">
    Loading
  </div>
</template>
<script>

import { User } from '@/resources/user'
import Swal from 'sweetalert2'


export default {
  data() {
    return {
      loading: false
    };
  },
  created: function () {
    this.check()
  },
  methods: {
    check() {
      this.loading = true
      User.verifyPaymentMethodToken({token: this.$route.query.token})
        .then(result => {
          this.success()
        })
        .catch(() => {
        }).finally(() => {
          this.loading = false
        })
    },
    async success() {
      const { value: res } = await Swal.fire({
        title: "Success!",
        text: "You have successfully updated your payment card",
        icon: "success",
        confirmButtonClass: "btn btn-success",
        buttonsStyling: false
      })

      this.$router.push({ name: 'Home', query: { refresh: true } })
    }
  }
}
</script>
<style></style>
