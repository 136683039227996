<template>
  <div class="row" v-loading.fullscreen.lock="loading">

    <el-drawer title="Client Delivery Form" :visible.sync="showDeliveryForm" direction="rtl" :before-close="handleClose" size="40%">
      <client-delivery-form :user="selected" v-if="selected" @close="handleClose()"></client-delivery-form>
    </el-drawer>

    <el-drawer title="Viewing Client" :visible.sync="showUser" direction="rtl" :before-close="handleClose" size="90%">
      <user-form :selected="selected" v-if="selected" style="padding: 10px" @updated="getUsers()"></user-form>
    </el-drawer>

    <div class="col-md-12 card">
      <div class="card-header">
        <div class="category">Clients</div>
      </div>
      <div class="card-body row">
        <div class="col-sm-2">
          <el-select class="select-default" v-model="pagination.perPage" placeholder="Per page">
            <el-option class="select-default" v-for="item in perPageOptions" :key="item" :label="item" :value="item">
            </el-option>
          </el-select>
        </div>
        <div class="col-sm-2">
          <div class="pull-right">
            <fg-input class="input-sm" placeholder="Search" v-model="term" addon-right-icon="nc-icon nc-zoom-split">
            </fg-input>
          </div>
        </div>
        <div class="col-md-12">
          <el-radio v-model="status" :label="1">Active</el-radio>
          <el-radio v-model="status" :label="2">Inactive</el-radio>

          <span> <span class="mr-3">|</span> <el-radio v-model="with_plan" :label="1">With Plan</el-radio>
            <el-radio v-model="with_plan" :label="2">All</el-radio></span>
        </div>


        <div class="col-sm-12 mt-2">
          <el-table class="table-striped" header-row-class-name="text-primary" :data="users" style="width: 100%">
            <el-table-column type="expand">
              <template slot-scope="props">
                <div style="padding-left: 50px">
                  <p>Roles: {{ props.row.role_names.join(", ") }}</p>
                  <p>Plan : {{ props.row.company && props.row.company.subscription ? props.row.company.subscription.name
                    : 'No Plan Yet' }}</p>
                  <hr>
                  <div>Latest Remarks: {{ props.row.company && props.row.company.company_plan ?
                    props.row.company.company_plan.remarks : '' }}</div>
                  <div v-if="props.row.company && props.row.company.company_profile">
                    <p>Company Name: {{ props.row.company.company_profile.name }}</p>
                    <p>Mobile Number: {{ props.row.company.company_profile.mobile_number }}</p>
                    <p>Website: {{ props.row.company.company_profile.website }}</p>
                  </div>

                </div>
              </template>
            </el-table-column>
            <el-table-column v-for="column in userColumns" :key="column.label" :min-width="column.minWidth"
              :prop="column.prop" :label="column.label">
            </el-table-column>
            <el-table-column class-name="td-actions" label="Started On" :min-width="120">
              <template slot-scope="props">
                <div v-if="props.row.company && props.row.company.first_company_plan">
                  <div>{{ formatDate(props.row.company.first_company_plan.created_at,'MM/DD/YYYY') }}</div>
                </div>
              </template>
            </el-table-column>

            <el-table-column class-name="td-actions" label="Current Plan" :min-width="200">
              <template slot-scope="props">
                <div v-if="props.row.company && props.row.company.subscription">
                  <div class="green-color">
                    {{ props.row.company && props.row.company.subscription ? props.row.company.subscription.name : 'No Plan Yet' }} -
                    {{ props.row.company && props.row.company.subscription ? props.row.company.subscription.price : ''}}
                  </div>
                  <div class="gray-text font-12">Start Date: {{ props.row.company && props.row.company.subscription ?
                    formatDate(props.row.company.subscription.start) : '' }}</div>
                  <div class="gray-text font-12">Due Date: {{ props.row.company && props.row.company.subscription ?
                    formatDate(props.row.company.due_date) : '' }}</div>
                </div>
                <div v-else>
                  No Plan
                </div>
              </template>
            </el-table-column>

            <el-table-column class-name="td-actions" label="Mailbox" :min-width="180">
              <template slot-scope="props">
                <div v-if="props.row.company" class="font-12">
                  {{ props.row.company.complete_address }}
                  <div class="orange-text">STORED MAILS: {{ props.row.stored_mails }}</div>

                  <div>
                    <div>
                      <el-link style="cursor:pointer" @click="handleShowDeliveryForm(props.row)"> <i class="fa fa-file"> </i>Delivery Form :</el-link>
                      <span v-if="props.row.company.mail_delivery_status == 1" >SUBMITTED</span>
                      <span v-if="props.row.company.mail_delivery_status == 0" class="gray-text">UNSUBMITTED</span>
                      <span v-if="props.row.company.mail_delivery_status == 2" class="green-color">APPROVED</span>
                      <span v-if="props.row.company.mail_delivery_status == 3" class="">REJECTED</span>
                    </div>

                  </div>
                </div>
              </template>
            </el-table-column>

            <el-table-column :min-width="120" class-name="td-actions" label="Status">
              <template slot-scope="props">
                <div>
                  <el-tag v-if="props.row.company && props.row.company.company_plan.is_cancel"
                    type="danger">CANCELLED</el-tag>
                </div>
                <el-tag v-if="props.row.status == 0">PENDING ACTIVATION</el-tag>
                <div v-if="props.row.company && props.row.company.subscription">
                  <div v-if="props.row.company.minutes_before_due_date > 0">

                    <el-tag
                      v-if="props.row.company.minutes_before_due_date > 0 && props.row.company.minutes_before_due_date <= 4320"
                      type="warning">EXPIRES SOON</el-tag>
                    <el-tag v-else type="success">SUBSCRIBED</el-tag>
                  </div>
                  <el-tag v-if="props.row.company.minutes_before_due_date <= 0" type="danger">EXPIRED</el-tag>
                  <div class="font-12">Remarks: {{ props.row.company && props.row.company.company_plan ?
                    props.row.company.company_plan.remarks : '' }}</div>
                </div>
                <el-tag v-if="props.row.status == 2" type="info">INACTIVE</el-tag>

              </template>
            </el-table-column>

            <el-table-column :min-width="150" class-name="td-actions" label="Actions">
              <template slot-scope="props">

                <el-tooltip class="item" effect="dark" content="View" placement="top-start">
                  <el-button type="primary" size="small" class="ml-1" @click="onShowUser(props.row)">
                    <i class="fa fa-eye"></i>
                  </el-button>
                </el-tooltip>


              </template>
            </el-table-column>
          </el-table>
        </div>
        <div class="col-sm-6 pagination-info">
          <p class="category">Found {{ pagination.total }} data</p>
        </div>
        <div class="col-sm-6">
          <p-pagination class="pull-right" v-model="pagination.currentPage" :per-page="pagination.perPage"
            :total="pagination.total">
          </p-pagination>
        </div>
      </div>
    </div>
  </div>
</template>
<script>

import { User } from '@/resources/user'

import Vue from 'vue'
import { Table, TableColumn, Select, Option, Drawer } from 'element-ui'
import PPagination from 'src/components/UIComponents/Pagination.vue'
import UserForm from '../Settings/Users/UserForm.vue'
import ClientDeliveryForm from './ClientDeliveryForm.vue'
Vue.use(Table)
Vue.use(TableColumn)
Vue.use(Select)
Vue.use(Option)
Vue.use(Drawer)

export default {
  components: {
    PPagination,
    UserForm,
    ClientDeliveryForm
  },
  data() {
    return {
     status: 1,
      with_plan: 1,
      showUser: false,
      users: [],
      perPageOptions: [5, 10, 15],
      pagination: {
        perPage: 10,
        currentPage: 1,
        total: 0
      },
      loading: false,
      term: null,
      selected: null,
      showDeliveryForm: false,
      userColumns: [
        {
          prop: 'name',
          label: 'Name',
          minWidth: 200
        },
        {
          prop: 'email',
          label: 'Email',
          minWidth: 250
        }
      ],
    }
  },
  watch: {
    status: function () {
      this.getUsers()
    },
    with_plan: function () {
      this.getUsers()
    },
    term: function () {
      if (!this.term || this.term.length > 2) {
        this.getUsers()
      }
    },
    'pagination.perPage': function () {
      if (this.pagination.currentPage == 1) {
        this.getUsers()
      } else {
        this.pagination.currentPage = 1
      }
    },
    'pagination.currentPage': function () {
      this.getUsers()
    }
  },
  created: function () {
    if (this.$route.query.client_id) {
      this.selected = { id: this.$route.query.client_id }
      this.onShowUser(this.selected)
    } else {
        this.getUsers()
    }
  },
  methods: {
    handleShowDeliveryForm(user) {
      this.showDeliveryForm = true
      this.selected = Object.assign({}, user)
    },
    formatTimestampToDate(value) {
      return this.$moment.unix(value).local().format("MM/DD/YYYY hh:mm");
    },
    formatDate(date, format) {
      return this.$moment(date).format(format || 'MM/DD/YYYY hh:mm')
    },
    onShowUser(user) {
      this.selected = Object.assign({}, user)
      this.showUser = true
      if (this.$route.query.client_id != user.id) this.$router.push({ query: { client_id: user.id } })
    },
    changeStatus(user, status) {
      User.update({ id: user.id, status: status, status_only: true })
        .then(result => {
          this.getUsers()
        })
        .catch(() => {

        }).finally(() => {
          this.loading = false
        })
    },
    handleNew() {
      this.showForm = true
      this.selected = {}
    },
    handleClose() {
      this.showUser = false
      this.showForm = false
      this.showDeliveryForm = false
      this.getUsers()
    },
    handleDelete(data) {
      this.showDelete = true
    },
    deleteItem() {

    },
    getUsers() {
      this.loading = true
      if (this.$route.query.client_id)this.$router.push({ query: {} })
      User.get({
        params: {
          roles: ['client'],
          with_mails_count: true,
          with_plan: this.with_plan,  
          term: this.term, status: this.status, page:
            this.pagination.currentPage, per_page: this.pagination.perPage
        }
      })
        .then(result => {
          if (result.data.users)
            this.users = result.data.users
          this.pagination = result.data.meta
        })
        .catch(() => {

        }).finally(() => {
          this.loading = false
        })
    }
  }
}
</script>
<style lang="scss"></style>
