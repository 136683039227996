<template>
  <div class="row" v-loading.fullscreen.lock="loading">
    <div class="col-lg-12">
      <stats-card small-title="Total Payments" :title="'$ '+ total_payments " type="warning"
      icon="nc-icon nc-money-coins">

      </stats-card>
    </div>
  </div>
</template>
<script>

import { Transaction } from '@/resources/transaction'
import StatsCard from '../../UIComponents/Cards/StatsCard.vue'

export default {
  components: {
    StatsCard
  },
  data() {
    return {
     
      total_payments: 0,
      loading: false,
    }
  },
  created: function () {
    this.getAnalytics()
  },
  methods: {
    getAnalytics() {
      this.loading = true
      Transaction.analytics()
        .then(result => {
          this.total_payments = result.data.total_payments
        })
        .catch(() => {
        }).finally(() => {
          this.loading = false
        })
    }
  }
}
</script>
<style lang="scss"></style>
