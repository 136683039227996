<template>
  <div>
    <management-bookings-list :type="1"></management-bookings-list>
  </div>
</template>
<script>
import ManagementBookingsList from '../MeetingRoom/Bookings/ManagementBookingsList.vue'


export default {
  components: { ManagementBookingsList },
  data() {
    return {

    }
  },
  created: function () {
    // This component will be shown one time. When the new client login for the first time
  },
  methods: {

  },
}
</script>
<style lang="scss"></style>
