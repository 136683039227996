<template>
  <ValidationObserver v-slot="{ handleSubmit }" v-loading="loading">
    <form @submit.prevent="handleSubmit(submit)">
      <div class="card">
        <div class="card-header">
          <h4 class="card-title">
            {{ form.id ? 'Update Transaction' : 'Create new Transaction' }} for {{ form.paid_by_user }}
          </h4>
        </div>
        <div class="card-body">
          <div class="form-group">
            <label>Description</label>
            <ValidationProvider name="description" rules="required" v-slot="{ passed, failed }">
              <fg-input type="text" :error="failed ? 'The description field is required' : null" :hasSuccess="passed" name="description"
                v-model="form.description">
              </fg-input>
            </ValidationProvider>
          </div>

          <div class="form-group">
            <label>Amount</label>
            <ValidationProvider name="amount" rules="required" v-slot="{ passed, failed }">
              <fg-input type="number" :error="failed ? 'The amount field is required' : null" :hasSuccess="passed" name="amount"
                v-model="form.amount">
              </fg-input>
            </ValidationProvider>
          </div>

          <div class="form-group">
            <label>Date</label>
            <ValidationProvider name="date" v-slot="{ passed, failed }">

              <fg-input type="date" :error="failed ? 'The date field is required' : null" :hasSuccess="passed"
                name="date" v-model="form.date">
              </fg-input>
            </ValidationProvider>
          </div>

        </div>
        <div class="card-footer text-right">
          <p-button type="info" native-type="submit">Save</p-button>
        </div>
      </div>
    </form>
  </ValidationObserver>
</template>
<script>

import { extend } from "vee-validate";
import { required } from "vee-validate/dist/rules";
import { Transaction } from '@/resources/transaction'

extend("required", required);

export default {
  props: ['transaction'],
  data() {
    return {
      form: {
        description: "",
        amount: null,
        date: null,
      },
      loading: false
    };
  },
  created: function () {
    this.form = Object.assign({}, this.transaction)
  },
  watch: {
    transaction() {
      this.form = Object.assign({}, this.transaction)
    }
  },
  methods: {
    submit() {
      this.loading = true
      let request = this.form.id ? Transaction.update(this.form) : Transaction.create(this.form)

      request
        .then(result => {
          this.$notify({
            type: 'success',
            title: "Success",
            text: 'Transaction has been saved',
          });

          this.$emit('update', true)
        })
        .catch(error => {
      
        }).finally(() => {
          this.loading = false
        })
    }
  }
}
</script>
<style></style>
