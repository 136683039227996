import api from './api'

const API = '/api/transactions'

export const Transaction = {
  payments: function(params) {
    return api.get(API + '/payments', params)
  },
  analytics: function(params) {
    return api.get(API + '/analytics', params)
  },
  download: function(params) {
    return api.create(API + '/download', params)
  },
  show: function(id, params) {
    return api.get(API + '/' + id, params)
  },
  create: function(params) {
    return api.create(API, params)
  },
  update: function(params) {
    return api.update(API+'/'+params.id, params)
  },
  delete: function(params) {
    return api.delete(API+'/'+params.id, params)
  }
}
