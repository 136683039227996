<template>
  <div v-loading="loading">
    <el-drawer title="Response" :visible.sync="showForm" direction="rtl">
      <div style="padding: 10px;">
          <mail-action-form :mail-action="selected" v-if="selected" @update="getMails()" action="open"></mail-action-form>
      </div>
    </el-drawer>

    <h4 class="card-title">Open and scan requests</h4>
    <card style="">
      <div>
        <el-radio-group v-model="status" size="small" @change="getMails()">
          <el-radio :label="0" border>New</el-radio>
          <el-radio :label="1" border>Done</el-radio>
        </el-radio-group>
      </div>
      <el-table :data="mail_actions" class="font-13 table-striped" header-row-class-name="text-primary">
        <el-table-column width="50" label="ID">
          <template slot-scope="scope">
            {{ scope.row.id }}
          </template>
        </el-table-column>
    
        <el-table-column min-width="100" label="Recipient" prop="recipient">
          <template slot-scope="scope">
            {{ scope.row.mail.recipient ? scope.row.mail.recipient.name : '' }}
          </template>
        </el-table-column>

        <el-table-column min-width="100" label="Pages" prop="pages">
                <template slot-scope="scope">
                 {{ scope.row.pages }}
                </template>
              </el-table-column>

          <el-table-column min-width="100" label="Price" prop="price">
              <template slot-scope="scope">
               $ {{ scope.row.price }}
              </template>
            </el-table-column>
       
        <el-table-column min-width="150" prop="attachments" label="Attachments">
          <template slot-scope="scope">
            <el-link type="primary" v-for="(a, i) in scope.row.mail.files" :key="i" @click="downloadAttachment(a, scope.row.id)">
              {{ a.file_name }}
            </el-link>
          </template>
        </el-table-column>
        <el-table-column width="120" label="Status">
          <template slot-scope="scope">
            <el-tag :type="scope.row.status ? 'primary':'warning'">{{ scope.row.status ? 'Done' : 'New' }}</el-tag>
          </template>
        </el-table-column>

        <el-table-column min-width="100" label="Actions" class-name="action" label-class-name="action">
          <template slot-scope="scope">
            <el-tooltip content="Attach and send" :open-delay="300" placement="top" v-if="scope.row.status == 0">
              <p-button type="info" size="sm" @click="onShowForm(scope.row)">
                Attach and send
              </p-button>
            </el-tooltip>
            <span v-if="status == 1">Updated by: {{ scope.row.updated_by }}</span>
          </template>
        </el-table-column>
      </el-table>

      <p-pagination v-if="mail_actions.length" class="pull-right" v-model="pagination.currentPage" :per-page="pagination.perPage"
        :total="pagination.total">
      </p-pagination>

    </card>

  </div>
</template>
<script>

import PPagination from 'src/components/UIComponents/Pagination.vue';
import { MailAction } from '@/resources/mail_action';
import MailActionForm from './MailActionForm.vue';

export default {
  components: {
    PPagination,
    MailActionForm
  },
  data() {
    return {
      mail_actions: [],
      pagination: {
        perPage: 5,
        currentPage: 1,
        perPageOptions: [5, 10, 25, 50],
        total: 0,
      },
      term: null,
      status: 0,
      loading: true,
      term: "",
      showForm: false,
      selected: null
    };
  },
  watch: {
    term: function () {
      if (!this.term || this.term.length > 2) {
        this.getMails()
      }
    },
    'pagination.perPage': function () {
      if (this.pagination.currentPage == 1) {
        this.getMails()
      } else {
        this.pagination.currentPage = 1
      }
    },
    'pagination.currentPage': function () {
      this.getMails()
    }
  },
  created: function () {
    this.getMails()
  },
  methods: {
    onShowForm(object) {
      this.showForm = true
      this.selected = Object.assign({}, object)
    },
    downloadAttachment(file, id) {
      const link = document.createElement("a");
      link.href = file.storage_path;
      link.download = file.file_name;
      link.click();
    },
    getMails() {
      this.loading = true
      this.showForm = false
      MailAction.open({params: {status: this.status}})
        .then(result => {
          this.mail_actions = result.data.mail_actions
          this.pagination = result.data.meta
        })
        .catch(error => {
          let message = error.response.data.message || 'Unable to fetch data this time'
          this.$notify({
            type: 'danger',
            title: "Error",
            text: message,
          });
        }).finally(() => {
          this.loading = false
        })
    }
  },
};
</script>
<style lang="scss"></style>

