<template>
  <div v-loading.fullscreen.lock="loading">
    <el-drawer title="Download Transactions" :visible.sync="showDownload" direction="rtl" :before-close="handleClose">
        <div style="padding: 10px;">
          <download-transactions></download-transactions>
        </div>
      </el-drawer>

    <h5>Transactions</h5>
    <hr>
    <div class="row" >
      <div class="col-md-3">
          <transaction-analytics></transaction-analytics>
          <el-button type="info" class="btn-block" v-allowed="'transactions, delete'" @click="goToAllTransactions()">Manage Transactions</el-button>
      </div>
      <div class="col-md-7">
        <el-card class="box-card" style="margin-bottom: 10px">
          <h5>Recent Transactions</h5>
          <el-table class="table-striped" header-row-class-name="text-primary" :data="payments" style="width: 100%">
           
            <el-table-column :min-width="100" class-name="td-actions" label="Paid By">
                <template slot-scope="props">
                  {{ props.row.paid_by_user }}
                </template>
              </el-table-column>
            <el-table-column :min-width="300" class-name="td-actions" label="Description">
              <template slot-scope="props">
                {{ props.row.description }}
              </template>
            </el-table-column>
            <el-table-column :min-width="80" class-name="td-actions" label="Amount">
                <template slot-scope="props">
                  $ {{ props.row.amount }}
                </template>
              </el-table-column>

              <el-table-column :min-width="120" class-name="td-actions" label="Paid Date">
                  <template slot-scope="props">
                    {{ props.row.paid_on }}
                  </template>
                </el-table-column>
          </el-table>

           <p-pagination class="pull-right" v-model="pagination.currentPage" :per-page="pagination.perPage"
                :total="pagination.total">
              </p-pagination>
        </el-card>
      </div>
      <div class="col-md-2">
        <el-button round type="info" @click="onShowDownloadTransaction()"><i class="nc-icon nc-money-coins" ></i> Download Transactions</el-button>
      </div>
    </div>
  </div>
</template>
<script>

import { Transaction } from '@/resources/transaction'
import PPagination from 'src/components/UIComponents/Pagination.vue'
import TransactionAnalytics from './TransactionAnalytics.vue'
import DownloadTransactions from './DownloadTransactions.vue'

export default {
  components: {
    PPagination,
    TransactionAnalytics,
    DownloadTransactions,
  },
  data() {
    return {
      showDownload: false,
      showDelete: false,
      showForm: false,
      payments: [],
      perPageOptions: [5, 10, 15],
      pagination: {
        perPage: 10,
        currentPage: 1,
        total: 0
      },
      loading: false,
      term: null,
    }
  },
  watch: {
    term: function () {
      if (!this.term || this.term.length > 2) {
        this.getPayments()
      }
    },
    'pagination.perPage': function () {
      if (this.pagination.currentPage == 1) {
        this.getPayments()
      } else {
        this.pagination.currentPage = 1
      }
    },
    'pagination.currentPage': function () {
      this.getPayments()
    }
  },
  created: function () {
    this.getPayments()
  },
  methods: {
    goToAllTransactions() {
       this.$router.push({ name: 'AllTransactions' })
    },
    handleClose() {
      this.showDownload = false
    },
    onShowDownloadTransaction() {
      this.showDownload = true
    },
    getPayments() {
      this.loading = true
      Transaction.payments({
        params:
          {  term: this.term, status: this.status, page: this.pagination.currentPage, per_page: this.pagination.perPage, order: 'desc' }
       }
      )
        .then(result => {
          this.payments = result.data.payments
          this.pagination = result.data.meta
        })
        .catch(() => {

        }).finally(() => {
          this.loading = false
        })
   }
  }
}
</script>
<style lang="scss"></style>
