<template>
  <div>
    <meeting-room :type="1"></meeting-room>
  </div>
</template>
<script>
import MeetingRoom from '../MeetingRoom/MeetingRoom.vue'


export default {
  components: { MeetingRoom },
  data() {
    return {
     
    }
  },
  created: function () {
    // This component will be shown one time. When the new client login for the first time

  },
  methods: {
 
  },
}
</script>
<style lang="scss"></style>
