<template>
  <div v-loading.fullscreen.lock="loading">
    <el-drawer title="Attention" :visible.sync="showDelete" direction="rtl" :before-close="handleClose">
      <div style="padding: 10px;" v-if="selected">
        <p>Remove this transaction? <el-link type="danger" @click="onDelete()" class="red-label">Yes, remove it!</el-link></p>
      </div>
    </el-drawer>

    <el-drawer title="Edit Transaction" :visible.sync="showForm" direction="rtl" :before-close="handleClose">
      <div style="padding: 10px;" v-if="selected">
        <transaction-form :transaction="selected" @update="getPayments()"></transaction-form>
      </div>
    </el-drawer>

    <h5>Transactions</h5>
    <hr>
    <div class="row">
      <div class="col-md-12">
        <el-card class="box-card" style="margin-bottom: 10px">
          <h5>Transactions</h5>
          <el-table class="table-striped" header-row-class-name="text-primary" :data="payments" style="width: 100%">
            <el-table-column :min-width="300" class-name="td-actions" label="Paid By">
                <template slot-scope="props">
                  {{ props.row.paid_by_user }}
                </template>
              </el-table-column>
            <el-table-column :min-width="300" class-name="td-actions" label="Description">
              <template slot-scope="props">
                {{ props.row.description }}
              </template>
            </el-table-column>
            <el-table-column :min-width="70" class-name="td-actions" label="Amount">
              <template slot-scope="props">
                $ {{ props.row.amount }}
              </template>
            </el-table-column>

            <el-table-column :min-width="120" class-name="td-actions" label="Paid Date">
              <template slot-scope="props">
                {{ props.row.paid_on }}
              </template>
            </el-table-column>

            <el-table-column :min-width="120" class-name="td-actions" label="Action">
                <template slot-scope="scope">
                  <el-tooltip content="Edit" :open-delay="300" placement="top" v-allowed="'transactions, delete'">
                      <p-button type="info" size="sm" icon class="ml-2" @click="onShowForm(scope.row)">
                          <i class="fa fa-pencil"></i>
                        </p-button>
                    </el-tooltip>
                  <el-tooltip content="Delete" :open-delay="300" placement="top" v-allowed="'transactions, delete'">
                    <p-button type="danger" size="sm" icon class="ml-2" @click="onShowDelete(scope.row)">
                        <i class="fa fa-trash"></i>
                      </p-button>
                  </el-tooltip>
                </template>
              </el-table-column>
          </el-table>

          <p-pagination class="pull-right" v-model="pagination.currentPage" :per-page="pagination.perPage"
            :total="pagination.total">
          </p-pagination>
        </el-card>
      </div>
    </div>
  </div>
</template>
<script>

import { Transaction } from '@/resources/transaction'
import PPagination from 'src/components/UIComponents/Pagination.vue'
import TransactionForm from './TransactionForm.vue'

export default {
  components: {
    PPagination,
    TransactionForm,
  },
  data() {
    return {
      showDelete: false,
      showForm: false,
      payments: [],
      perPageOptions: [5, 10, 15],
      pagination: {
        perPage: 10,
        currentPage: 1,
        total: 0
      },
      selected: null,
      loading: false,
      term: null,
    }
  },
  watch: {
    term: function () {
      if (!this.term || this.term.length > 2) {
        this.getPayments()
      }
    },
    'pagination.perPage': function () {
      if (this.pagination.currentPage == 1) {
        this.getPayments()
      } else {
        this.pagination.currentPage = 1
      }
    },
    'pagination.currentPage': function () {
      this.getPayments()
    }
  },
  created: function () {
    this.getPayments()
  },
  methods: {
    handleClose() {
      this.showDelete = false
      this.showForm = false
    },
    onShowDelete(transaction) {
      this.showDelete = true
       this.selected = Object.assign({}, transaction)
    },
    onShowForm(transaction) {
      this.showForm = true
      this.selected = Object.assign({}, transaction)
    },
    onDelete() {
      this.loading = true
      Transaction.delete({ id: this.selected.id })
        .then(result => {
          this.$notify({
            type: 'success',
            title: "Success",
            text: 'Transaction has been removed successfully',
          });
          this.getPayments()
        })
        .catch(error => {

        }).finally(() => {
          this.loading = false
        })
    },
    getPayments() {
      this.handleClose()
      this.loading = true
      Transaction.payments({
        params:
          { term: this.term, status: this.status, page: this.pagination.currentPage, per_page: this.pagination.perPage }
      }
      )
        .then(result => {
          this.payments = result.data.payments
          this.pagination = result.data.meta
        })
        .catch(() => {

        }).finally(() => {
          this.loading = false
        })
    }
  }
}
</script>
<style lang="scss"></style>
