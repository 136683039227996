<template>
  <div style="padding-left: 20px" v-if="user && user.company" v-loading="loading">
    <div v-if="user.company.mail_delivery_status > 0">
      <p>DELIVERY FORM FROM CLIENT {{ user.name }}<br>
        <a :href="user.company.mail_delivery_form_file_url" target="_blank">DOWNLOAD</a>
      </p>
      <el-switch v-model="form.mail_delivery_status" active-text="Approve" inactive-text="Reject" :active-value="2"
        :inactive-value="3">
      </el-switch>

      <div class="form-group" v-if="form.mail_delivery_status == 3">
        <br>
        <label>Please provide some reason on rejecting this form</label>
        <el-input type="textarea" v-model="form.mail_delivery_remarks" placeholder="some notes"></el-input>
      </div>
      <div>
        <br>

        <el-button plain type="primary" @click="handleSubmit">SAVE</el-button>
      </div>
    </div>
    <div v-else>
      Client {{ user.name }} has not uploaded a form yet
    </div>
  </div>
</template>
<script>
import { extend } from "vee-validate";
import { required, email } from "vee-validate/dist/rules";

import { Company } from '@/resources/company'

extend("required", required);
extend("email", email);
import Swal from 'sweetalert2'

export default {
  props: ['user'],
  data() {
    return {
      form: {
        mail_delivery_status: null,
        mail_delivery_remarks: null
      },
      loading: false,
    };
  },
  watch: {
    user: {
      handler: function (newVal) {
        this.initData()
      },
      deep: true
    }
  },
  created: function () {
    this.initData()
  },
  methods: {
    initData() {
      if (this.user && this.user.company) {
        this.form.mail_delivery_status = this.user.company.mail_delivery_status
        this.form.mail_delivery_remarks = this.user.company.mail_delivery_remarks
      }
    },
    handleSubmit() {
      this.loading = true
      Company.updateMailForm(this.user.company.id, this.form)
        .then(result => {
          this.$notify({
            message: 'Delivery form has been updated',
            type: 'success'
          })
          this.$emit('close')
        })
        .catch(error => {
          this.$notify({
            message: 'Unable to update this time',
            type: 'danger'
          })
        }).finally(() => {
          this.loading = false
        })
    },
  }
}
</script>
<style lang="scss">
.card-wizard .picture-container .picture {
  input[type="file"] {
    display: none;
  }
}
</style>
