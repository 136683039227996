<template>
  <div v-if="user" class="">
    <div class="row">
      <div class="col-md-3">
                <datepicker v-model="date" input-class="form-control" bootstrap-styling placeholder="Search Date"></datepicker>

        <el-autocomplete
          style="width:100%"
          clearable
          @change="onRoomChanged"
            v-model="roomName"
            :fetch-suggestions="getRooms"
            placeholder="Search Room"
            value="name"
            label="name"
            @select="handleSelect"
          ></el-autocomplete>

         <div class="block mt-3">
            <el-button type="info" v-if="!all" @click="showAll()">Show All</el-button>
          </div>
      </div>
      <div class="col-md-6">
        <!-- <div class="row">
          <div class="col-md-4">
            <div class="demonstration mb-3">Search Booked Rooms</div>
          </div>
          <div class="col-md-8">
            <datepicker v-model="date" input-class="form-control" bootstrap-styling></datepicker>
          </div>
        </div> -->
       
        
        <el-card class="list-card" v-for="booking, i in bookings" :key="i">

          <meeting-room-picture-preview :meeting_room="booking.meeting_room"></meeting-room-picture-preview>
          <hr>
          <div>
            <el-tag type="success" v-if="booking.status == 0" style="margin-left: 0">Unpaid</el-tag>
            <el-tag type="primary" v-if="booking.status == 2" style="margin-left: 0">Booked</el-tag>
          </div>
          <h5>{{ booking.meeting_room.name }} - {{ booking.meeting_room.office.name }}</h5>
          <div>Capacity: {{ booking.meeting_room.capacity }} people</div>
          <div>Address: {{ booking.meeting_room.office.address.full_address }}</div>
          <div>Total Hours: {{ booking.hours.length }} hrs</div>
          <div v-if="user.id !== booking.user.id">Booked by: {{ booking.user.name }}</div>
          <div>          
            <el-tag class="ml-0"> {{ getFormattedDate(booking.date) }}  | {{ booking.time }}</el-tag>
          </div>
        </el-card>

        <div class="no-data-text" v-if="!pagination.total">No bookings found</div>
        <div class="row" v-if="pagination.total">
          <div class="col-sm-6 pagination-info">
            <p class="category">Found {{ pagination.total }} data</p>
          </div>
          <div class="col-sm-6">
            <p-pagination class="pull-right"
                          v-model="pagination.currentPage"
                          :per-page="pagination.perPage"
                          :total="pagination.total">
            </p-pagination>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>

import { mapState } from 'vuex';
import { MeetingRoom } from '@/resources/meeting_room'
import Datepicker from 'vuejs-datepicker';
import PPagination from 'src/components/UIComponents/Pagination.vue'
import MeetingRoomPicturePreview from '../Client/MeetingRoomPicturePreview.vue';

export default {
  props: ['type'],
  components: { Datepicker, PPagination, MeetingRoomPicturePreview },
  data() {
    return {
      loading: false,
      date: null,
      bookings: [],
      pagination: {
        perPage: 5,
        currentPage: 1,
        total: 0
      },
      room: null,
      roomName: null,
      all: true
    }
  },
  created: function () {
    // this.getUser(this.$route.params.id)
    this.getBookings()
  },
  watch: {
    date: function () {
      this.all = false
      this.getBookings()
    }
  },
  methods: {
    getFormattedDate(date) {
      let moment = this.$moment()
      let split = date.split('-')
      moment.set('year', split[0]);
      moment.set('month', split[1]-1);  // April
      moment.set('date', split[2]);

      return moment.format('MMMM DD, YYYY')
    },
    showAll() {
      this.all = true
      this.date = null
      this.getBookings()
    },
    handleSelect(item) {
      this.room = Object.assign({}, item)
      this.getBookings()
    },
    onRoomChanged(value) {
      console.log(value)
      if (!value) {
        this.room = null
        this.getBookings()
      }
    },
    getRooms(queryString, cb) {
      MeetingRoom.get({ params: { term: queryString, status: 1, page: 1, per_page: 5, type: this.type} })
        .then(result => {
          let list = []

          result.data.rooms.forEach(room => {
            list.push({value: room.name, id: room.id})
          });

          cb(list)
        })
        .catch((err) => {
          console.log(err)
          cb([])
        }).finally(() => {
         
        })
    },
    getBookings() {
      this.loading = true

      let d = this.$moment(this.date);

      d = d.isValid() ? d.format('YYYY/MM/DD') : null
      if (this.all) d = null
      // if (!d.isValid()) {
        // this.$notify({
        //   type: 'error',
        //   title: "Error",
        //   text: 'Please select a date',
        // });
        // return false
      // }

      MeetingRoom.getBookings({ params: { page: 1, per_page: 999, date: d, all: this.all,  meeting_room_id: this.room ? this.room.id : null, type: this.type  } })
        .then(result => {
          this.bookings = result.data.bookings
          this.pagination = result.data.meta
        })
        .catch((error) => {
          console.log(error)
        }).finally(() => {
          this.loading = false
        })
    }
  },
  computed: mapState(['user']),
}
</script>
<style lang="scss"></style>
